@import 'vendors.css';

body {
  font-family: 'Poppins', sans-serif;
}

/* WebKit */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
